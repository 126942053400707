import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  useForm,
  FormProvider,
  useFieldArray,
  useFormContext,
} from "react-hook-form";
import { RiArrowRightLine, RiSave3Line } from "react-icons/ri";

import {
  AtomDatePicker,
  Button,
  Card,
  CardBody,
  CardHeader,
  InputForm,
  Spinner,
} from "components";
import CustomDropzoneForm from "./Dropzone";
import CustomSelectForm from "./Select";
import CustomInput from "./Input";
import { isNaN, sortBy } from "lodash";
import AtomSelect from "components/atoms/Select";
import { getAwpDetail, getList } from "services/danaProgram/awsService";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getIspList } from "services/danaProgram/masterDataService";
import { CgSpinner } from "react-icons/cg";
import { AiOutlinePlus } from "react-icons/ai";
import { BiTrash } from "react-icons/bi";
import { fileUpload } from "services/danaProgram/fileService";
import FileDetail from "components/molecules/FileDetail";
import moment from "moment";
import {
  getActivityReportDetail,
  postActivityReportStep1,
} from "services/danaProgram/activityReportService";
import { useSearchParams } from "react-router-dom";
import { enqueueSnackbar } from "notistack";

const validationSchema = yup.object({
  main_activity: yup
    .object()
    .shape({
      value: yup.string(),
      label: yup.string(),
    })
    .test("required", "Kegiatan Utama wajib diisi", (value, ctx) => {
      return !!value?.value && !!value?.label;
    })
    .nullable(),
  involvement: yup.object().shape({
    male: yup
      .number()
      .required("Jumlah Keterlibatan wajib diisi")
      .min(1, "Jumlah Keterlibatan minimal 1")
      .typeError("Jumlah Keterlibatan harus berupa angka")
      .transform((_, val) => {
        if (val === "") return null;
        else if (isNaN(Number(val))) return false;
        return Number(val);
      }),
    female: yup
      .number()
      .required("Jumlah Keterlibatan wajib diisi")
      .min(1, "Jumlah Keterlibatan minimal 1")
      .typeError("Jumlah Keterlibatan harus berupa angka")
      .transform((_, val) => {
        if (val === "") return null;
        else if (isNaN(Number(val))) return false;
        return Number(val);
      }),
  }),
  activity_result: yup.object().shape({
    activity_result: yup.string().required("Hasil Kegiatan wajib diisi"),
    attachment: yup
      .array()
      .of(yup.mixed())
      .min(1, "Dokumen Bukti Pelaksanaan Kegiatan wajib diisi")
      .test(
        "fileType",
        "Dokumen Bukti Pelaksanaan Kegiatan harus dalam format .png, .jpg, .pdf, .docx",
        (values) => {
          return (
            values.length &&
            values.every((x) => x !== null) &&
            (
              values.every((x) => (x.type || x.mimeType) === "image/png") ||
              values.every((x) => (x.type || x.mimeType) === "image/jpeg") ||
              values.every((x) => (x.type || x.mimeType) === "application/pdf") ||
              values.every((x) => (x.type || x.mimeType) === "application/msword") ||
              values.every((x) => (x.type || x.mimeType) === "application/vnd.openxmlformats-officedocument.wordprocessingml.document")
            )
          );
        }
      )
      .nullable(),
  }),
  partners: yup
    .string()
    .required("Mitra / Pemangku Yang Dilibatkan wajib diisi"),
  activity_goals: yup
    .string().required("Tujuan Kegiatan wajib diisi"),
  start_date: yup.string().required("Tanggal Kegiatan wajib diisi"),
  end_date: yup.string().required("Tanggal Kegiatan wajib diisi"),
});

const ActivityResult = ({ onNext, defaultValues }) => {
  let [searchParams] = useSearchParams();

  let { id: formId } = useParams();
  let mode = searchParams.get("mode");

  const navigate = useNavigate();

  const [isChangeIsp, setIsChangeIsp] = useState(true);
  const [selectedIsp, setSelectedIsp] = useState(0);
  const [isSaveAsDraft, setIsSaveAsDraft] = useState(false);

  const [awp, setAwp] = useState([]);
  const [pageAwp, setPageAwp] = useState(1);
  const [lastPageAwp, setLastPageAwp] = useState(1);

  const [activityOption, setActivityOption] = useState([]);

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  // console.log(methods.formState.errors)

  const { getValues, setValue, control } = methods;

  const ActivityReportsId = localStorage.getItem("ActivityReportsId") || null;

  // For Edit
  const { data: dataDetail, isFetching: isFetchingDetail } = useQuery({
    enabled: ActivityReportsId !== null,
    queryKey: ["get-activity-report-detail"],
    queryFn: async () => {
      const res = await getActivityReportDetail({
        id: ActivityReportsId,
      });
      return res.data.data;
    },
  });

  useEffect(() => {
    if (dataDetail && !!ActivityReportsId) {
      let startDate = dataDetail?.step_1?.start_date.split("/");
      let endDate = dataDetail?.step_1?.end_date.split("/");
      methods.reset({
        project: {
          value: dataDetail?.step_1?.project_id,
          label: dataDetail?.step_1?.project_name,
        },
        main_activity: {
          detail: dataDetail?.step_1?.main_activity.detail,
          value: dataDetail?.step_1?.main_activity.main_activity_id,
          label: dataDetail?.step_1?.main_activity.main_activity_name,
        },
        output_id: dataDetail?.step_1?.output_id,
        output_name: dataDetail?.step_1?.output_name,
        start_date: new Date(`${startDate[2]}/${startDate[1]}/${startDate[0]}`),
        end_date: new Date(`${endDate[2]}/${endDate[1]}/${endDate[0]}`),
        // activity_goals: dataDetail?.step_1?.purposes?.map((item) => ({
        //   label: item,
        //   value: item,
        // })),
        activity_goals: dataDetail?.step_1?.purposes,
        // partners: dataDetail?.step_1?.partners?.map((item) => ({
        //   label: item,
        //   value: item,
        // })),
        partners: dataDetail?.step_1?.partners,
        involvement: {
          male: dataDetail?.step_1?.total_male,
          female: dataDetail?.step_1?.total_female,
        },
        activity_result: {
          activity_result: dataDetail?.step_1?.activity_result,
          attachment: dataDetail?.step_1?.document_support,
        },
        safeguards: dataDetail?.step_1?.safeguards,
      });
    }
  }, [dataDetail]);
  // End Section For Edit

  const { data: ispList, isFetching: isFetchingIspList } = useQuery({
    queryKey: ["get-isp-list"],
    queryFn: async () => {
      const res = await getIspList();

      return res.data.data;
    },
  });

  useEffect(() => {
    if (ispList && !ActivityReportsId) {
      let sorted = sortBy(ispList, (el) => {
        return parseInt(el?.nama.split(" ")[1]);
      });
      setValue(
        "safeguards",
        sorted.map((item) => {
          return {
            principle_name: item?.deskripsi,
            activity_list: [
              {
                activity: "",
                description: "",
                document: [],
              },
            ],
          };
        })
      );
    }
    setTimeout(() => {
      setIsChangeIsp(false);
    }, 300);
  }, [ispList]);

  const { fields } = useFieldArray({
    keyName: "id",
    name: "safeguards",
    control,
  });

  const { data: awpList } = useQuery({
    queryKey: ["get-awp-list", pageAwp],
    queryFn: async () => {
      const res = await getList({ page: pageAwp, status: "dikirim" });

      return res.data.data;
    },
  });

  const reachBottomAwp = () => {
    if (pageAwp < lastPageAwp) {
      setPageAwp(pageAwp + 1);
    }
  };

  useEffect(() => {
    if (awpList) {
      let mapped = awpList?.data?.map((item) => ({
        label: item?.proposal_number,
        value: item?._id,
      }));
      setAwp((prevState) => [...prevState, ...mapped]);
      setPageAwp(awpList?.page);
      setLastPageAwp(awpList?.lastPage);
    }
  }, [awpList]);

  const { data: awpDetail, isFetching: isFetchingAWPDetail } = useQuery({
    enabled: !!methods.getValues("project")?.value,
    queryKey: ["get-awp-detail", methods.watch("project")],
    queryFn: async () => {
      const res = await getAwpDetail(methods.getValues("project")?.value, {});

      return res.data.data;
    },
  });

  useEffect(() => {
    if (awpDetail) {
      let activity = awpDetail?.output?.flatMap((parent, index) => {
        return parent?.activity.map((item) => ({
          id: item?._id,
          name: item?.name,
          detail: item?.detail?.map((item) => ({
            label: item?.name,
            value: item?._id,
          })),
          output: parent?.name,
          output_id: item?.awp_output_id,
        }));
      });
      setActivityOption(activity);
    }
  }, [awpDetail]);

  const onSubmit = (payload, isDraft) => {
    if (mode === "detail") {
      onNext();
    } else {
      setIsSaveAsDraft(isDraft);
      postFile.mutate({
        safeguards: payload?.safeguards,
        additionalDocs: payload?.activity_result?.attachment,
      });
    }
  };

  const postFile = useMutation({
    mutationKey: ["post-file-safeguard-activity-report"],
    mutationFn: async (data) => {
      let safeguardFile = data?.safeguards?.map(async (item, index) => {
        const allActivityPromises = item.activity_list.map(async (activity) => {
          const filePromises = activity.document?.map(async (file) => {
            if (!file?.fileId) {
              const formData = new FormData();
              formData.append("file", file);
              const res = await fileUpload(formData);
              return {
                fileId: res.data.data.id,
                fileName: res.data.data.name,
                fileSize: res.data.data.size,
                mimeType: res.data.data.mimeType,
                path: res.data.data.key,
              };
            } else {
              return file;
            }
          });
          return Promise.all(filePromises);
        });
        return Promise.all(allActivityPromises);
      });
      let additionalDocs = data?.additionalDocs?.map(async (item, index) => {
        if (!item?.fileId) {
          const formData = new FormData();
          formData.append("file", item);
          const res = await fileUpload(formData);
          return {
            fileId: res.data.data.id,
            fileName: res.data.data.name,
            fileSize: res.data.data.size,
            mimeType: res.data.data.mimeType,
            path: res.data.data.key,
          };
        } else {
          return item;
        }
      });
      const safeguardResult = await Promise.all(safeguardFile);
      const additionalResult = await Promise.all(additionalDocs);
      return { safeguardResult, additionalResult };
    },
    onSuccess: async (res) => {
      setValue("activity_result.attachment", [...res.additionalResult]);
      res.safeguardResult.map((activity, indexSafeguard) => {
        activity?.map((file, indexActivity) => {
          setValue(
            `safeguards.${indexSafeguard}.activity_list.${indexActivity}.document`,
            file
          );
        });
      });

      let payload = {
        id: ActivityReportsId,
        project_id: getValues("project.value"),
        project_name: getValues("project.label"),
        main_activity: {
          detail: getValues("main_activity.detail"),
          main_activity_id: getValues("main_activity.value"),
          main_activity_name: getValues("main_activity.label"),
        },
        output_id: getValues("output_id"),
        output_name: getValues("output_name"),
        start_date: moment(getValues("start_date")).format("DD/MM/YYYY"),
        end_date: moment(getValues("end_date")).format("DD/MM/YYYY"),
        // purposes: getValues("activity_goals").map((item) => item?.value),
        // partners: getValues("partners")?.map((item) => item?.value),
        purposes: getValues("activity_goals"),
        partners: getValues("partners"),
        total_male: getValues("involvement.male"),
        total_female: getValues("involvement.female"),
        activity_result: getValues("activity_result.activity_result"),
        document_support: getValues("activity_result.attachment"),
        safeguards: getValues("safeguards"),
      };
      postStep1.mutate(payload);
    },
  });

  const postStep1 = useMutation({
    mutationKey: ["post-step-1-activity-report"],
    mutationFn: async (payload) => {
      const res = await postActivityReportStep1(payload);
      return res.data.data;
    },
    onSuccess: (res) => {
      if (ActivityReportsId === null) {
        localStorage.setItem("ActivityReportsId", res?._id);
      }

      if (isSaveAsDraft) {
        navigate("/activity-report", { replace: true });
        enqueueSnackbar('Draft berhasil disimpan', { variant: 'success' });
      } else {
        onNext({
          ...res,
          main_activity: {
            detail: getValues("main_activity.detail"),
            main_activity_id: getValues("main_activity.value"),
            main_activity_name: getValues("main_activity.label"),
          },
        });
      }
    },
  });

  return (
    <FormProvider {...methods}>
      {isFetchingDetail || isFetchingIspList || isFetchingAWPDetail ? (
        <div className="w-full h-full flex justify-center">
          <CgSpinner className="animate-spin text-primary-400" />
        </div>
      ) : (
        <form noValidate autoComplete="off">
          <div className="space-y-4 w-full overflow-hidden">
            <Card>
              <CardHeader>
                <div>Pelaksanaan Kegiatan</div>
              </CardHeader>
              <CardBody>
                <div className="space-y-5">
                  <AtomSelect
                    label={"Proyek"}
                    controllerName={"project"}
                    options={awp}
                    onMenuScrollToBottom={reachBottomAwp}
                    required
                    onChange={() => {
                      methods.setValue('main_activity', null);
                      methods.setValue('output_id', null);
                      methods.setValue('output_name', null);
                    }}
                    disable={mode === "detail"}
                  />
                  <div>
                    <label className="label font-semibold text-[14px]">
                      <span className={`label-text`}>
                        Kegiatan Utama
                        <span className="text-[#F04438] text-[14px]">*</span>
                      </span>
                    </label>
                    <CustomSelectForm
                      name="main_activity"
                      placeholder="Kegiatan Utama"
                      options={activityOption?.map((item) => ({
                        label: item?.name,
                        value: item?.id,
                        output: item?.output,
                        output_id: item?.output_id,
                        detail: item?.detail,
                      }))}
                      onChange={(obj) => {
                        methods.setValue("output_id", obj.output_id);
                        methods.setValue("output_name", obj.output);
                      }}
                      disabled={mode === "detail"}
                    />
                  </div>
                  <InputForm
                    controllerName={`output_name`}
                    className={`text-sm px-4 rounded-md border-[#D0D5DD] focus-visible:!outline-none focus:!outline-none !shadow-none !ring-0 focus:!border-primary-700 w-full`}
                    label={"Output"}
                    placeholder={"Output"}
                    disabled
                  />
                  <div className="flex gap-5">
                    <div className="w-full grid grid-cols-2 gap-3">
                      <AtomDatePicker
                        className="max-h-[38px] mt-0 w-full rounded-md border-gray-200 border-solid text-gray-400 text-sm outline-none focus:outline-none focus:ring-0 focus:border-0"
                        controllerName="start_date"
                        label={"Tanggal Kegiatan"}
                        placeholder="Select a date"
                        format={"dd/MM/yyyy"}
                        datepickerOptions={{
                          maxDate: methods.getValues("end_date"),
                        }}
                        asText={mode === "detail"}
                      />
                      <div className="flex items-end">
                        <AtomDatePicker
                          className="max-h-[38px] flex w-full rounded-md border-gray-200 border-solid text-gray-400 text-sm outline-none focus:outline-none focus:ring-0 focus:border-0"
                          controllerName="end_date"
                          label={""}
                          placeholder="Select a date"
                          datepickerOptions={{
                            minDate: methods.getValues("start_date"),
                          }}
                          format={"dd/MM/yyyy"}
                          asText={mode === "detail"}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="w-full">
                    <label className="label font-semibold text-[14px]">
                      <span className={`label-text`}>
                        Tujuan Kegiatan
                        <span className="text-[#F04438] text-[14px]">*</span>
                      </span>
                    </label>
                    {/* <CustomSelectForm
                      type="creatable"
                      name="activity_goals"
                      placeholder="Tujuan Kegiatan"
                      disabled={mode === "detail"}
                    /> */}
                    <InputForm
                      controllerName={`activity_goals`}
                      className={`text-sm px-4 rounded-md border-[#D0D5DD] focus-visible:!outline-none focus:!outline-none !shadow-none !ring-0 focus:!border-primary-700 w-full`}
                      // label={"Tujuan Kegiatan"}
                      placeholder={"Tujuan Kegiatan"}
                      disabled={mode === `detail`}
                      textArea={true}
                    />
                  </div>
                  <div className="w-full">
                    <label className="label font-semibold text-[14px]">
                      <span className={`label-text`}>
                        Mitra / Pemangku Yang Dilibatkan
                        <span className="text-[#F04438] text-[14px]">*</span>
                      </span>
                    </label>
                    {/* <CustomSelectForm
                      type="creatable"
                      name="partners"
                      placeholder="Mitra / Pemangku Yang Dilibatkan"
                      disabled={mode === "detail"}
                    /> */}
                    <InputForm
                      controllerName={`partners`}
                      className={`text-sm px-4 rounded-md border-[#D0D5DD] focus-visible:!outline-none focus:!outline-none !shadow-none !ring-0 focus:!border-primary-700 w-full`}
                      // label={"Mitra / Pemangku Yang Dilibatkan"}
                      placeholder={"Mitra / Pemangku Yang Dilibatkan"}
                      disabled={mode === `detail`}
                      textArea={true}
                    />
                  </div>
                  <div className="text-lg font-semibold text-primary-700">
                    Keterlibatan
                  </div>
                  <div className="flex gap-5">
                    <div className="w-full">
                      <label className="label font-semibold text-[14px]">
                        <span className={`label-text`}>
                          Perempuan
                          <span className="text-[#F04438] text-[14px]">*</span>
                        </span>
                      </label>
                      <CustomInput
                        name={`involvement.female`}
                        placeholder="Perempuan"
                        asText={mode === "detail"}
                      />
                    </div>
                    <div className="w-full">
                      <label className="label font-semibold text-[14px]">
                        <span className={`label-text`}>
                          Laki-laki
                          <span className="text-[#F04438] text-[14px]">*</span>
                        </span>
                      </label>
                      <CustomInput
                        name={`involvement.male`}
                        placeholder="Laki-laki"
                        asText={mode === "detail"}
                      />
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <div>Hasil Kegiatan</div>
              </CardHeader>
              <CardBody>
                <div className="space-y-5">
                  <InputForm
                    controllerName={`activity_result.activity_result`}
                    className={`text-sm py-2 px-4 rounded-md border-[#D0D5DD] focus-visible:!outline-none focus:!outline-none !shadow-none !ring-0 focus:!border-primary-700`}
                    label={"Hasil kegiatan dan atau kemajuan"}
                    placeholder={"Hasil kegiatan dan atau kemajuan"}
                    textArea={true}
                    rows={5}
                    disabled={mode === "detail"}
                  />
                  <div className="w-full">
                    <label className="label font-semibold text-[14px]">
                      <span className={`label-text`}>
                        Upload Dokumen Bukti Pelaksanaan Kegiatan
                        <span className="text-[#F04438] text-[14px]">*</span>
                      </span>
                    </label>
                    {methods?.watch("activity_result.attachment").length > 0 ? (
                      <>
                        {methods
                          ?.getValues("activity_result.attachment")
                          .map((item, idx) => (
                            <>
                              <FileDetail
                                key={idx}
                                file={item}
                                onRemove={() => {
                                  setValue("activity_result.attachment", []);
                                }}
                                hideDelete={mode === "detail"}
                              />
                              <p className="text-[#F04438] text-[14px]">
                                {methods.formState.errors?.activity_result?.attachment?.message}
                              </p>
                            </>
                          ))}
                      </>
                    ) : (
                      <CustomDropzoneForm
                        name="activity_result.attachment"
                        // acceptedFile={{ "application/pdf": [] }}
                        // informationText="PDF (max. 10MB)"
                        informationText={"PNG, JPG, PDF, Docx up to 1MB"}
                        maxSize={1}
                        errorMessage={methods.formState.errors?.activity_result?.attachment?.message}
                      />
                    )}
                  </div>
                </div>
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <div>Safeguard yang Diimplementasi</div>
              </CardHeader>
              <div className="p-5 w-full">
                <div className="flex gap-3 max-w-full overflow-x-scroll">
                  {sortBy(ispList, (el) => {
                    return parseInt(el?.nama.split(" ")[1]);
                  }).map((item, index) => (
                    <div
                      key={index}
                      className={`px-5 py-3 whitespace-nowrap rounded-md cursor-pointer ${
                        index === selectedIsp
                          ? "bg-primary-700 text-white font-semibold"
                          : "bg-slate-200"
                      }`}
                      onClick={() => {
                        setIsChangeIsp(true);
                        setSelectedIsp(index);
                        setTimeout(() => {
                          setIsChangeIsp(false);
                        }, 300);
                      }}
                    >
                      ISP {index + 1}
                    </div>
                  ))}
                </div>
                {isChangeIsp ? (
                  <div className="w-full flex justify-center m-5">
                    <CgSpinner className="animate-spin text-primary-400" />
                  </div>
                ) : (
                  <div className="mt-5">
                    <InputForm
                      controllerName={`safeguards.${selectedIsp}.principle_name`}
                      className={`text-sm py-2 px-4 rounded-md border-[#D0D5DD] focus-visible:!outline-none focus:!outline-none !shadow-none !ring-0 focus:!border-primary-700 w-full`}
                      label={""}
                      disabled
                      rows={5}
                    />
                    <SafeguardActivitySection
                      alias={`safeguards.${selectedIsp}`}
                      mode={mode}
                    />
                  </div>
                )}
              </div>
            </Card>
          </div>
          <div className="block md:flex items-center justify-between px-10 py-5 border-[2px] border-gray-200 rounded-3xl mt-4 bg-white mb-10">
            <Button
              type="button"
              className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300 w-40"
              onClick={() => navigate("/activity-report")}
            >
              Batal
            </Button>
            <div className="flex items-center gap-2">
              <Button
                type="button"
                onClick={methods.handleSubmit((data) => onSubmit(data, true))}
                // disabled={methods.formState.isValidating || !methods.formState.isValid}
                disabled={
                  /^\s*$/.test(methods.watch("proposal_number"))
                }
              >
                <RiSave3Line />
                Simpan & Draft
              </Button>
              <Button
                type="button"
                className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
                onClick={methods.handleSubmit((data) => onSubmit(data))}
              >
                Selanjutnya
                <RiArrowRightLine className="text-white" />
              </Button>
            </div>
          </div>
        </form>
      )}
    </FormProvider>
  );
};

const SafeguardActivitySection = ({ alias, mode }) => {
  const methods = useFormContext();

  const { fields, append, remove } = useFieldArray({
    name: `${alias}.activity_list`,
    keyName: "id",
  });
  return (
    <Fragment>
      {fields?.map((item, index) => (
        <div key={item.id} className="mt-3 flex flex-col gap-3">
          <div className="w-full p-3 bg-primary-600 font-semibold text-white flex justify-between items-center">
            <div>Kegiatan {index + 1}</div>
            {fields.length > 1 && mode !== "detail" && (
              <div
                className="p-2 bg-error-500 rounded-md cursor-pointer"
                onClick={() => {
                  remove(index);
                }}
              >
                <BiTrash className=" text-white" />
              </div>
            )}
          </div>
          <div className="w-full flex flex-col gap-2">
            <InputForm
              controllerName={`${alias}.activity_list.${index}.activity`}
              className={`w-full text-sm py-2 px-4 rounded-md border-[#D0D5DD] focus-visible:!outline-none focus:!outline-none !shadow-none !ring-0 focus:!border-primary-700`}
              label={"Kegiatan"}
              placeholder={"-"}
              disabled={mode === "detail"}
            />
            <InputForm
              controllerName={`${alias}.activity_list.${index}.description`}
              className={`w-full text-sm py-2 px-4 rounded-md border-[#D0D5DD] focus-visible:!outline-none focus:!outline-none !shadow-none !ring-0 focus:!border-primary-700`}
              label={"Deskripsi"}
              placeholder={"-"}
              textArea={true}
              rows={4}
              disabled={mode === "detail"}
            />
            <div className="w-full">
              <label className="label font-semibold text-[14px]">
                <span className={`label-text`}>Dokumen Pendukung</span>
              </label>
              {methods.watch(`${alias}.activity_list.${index}.document`)
                ?.length > 0 ? (
                <>
                  {methods
                    .getValues(`${alias}.activity_list.${index}.document`)
                    .map((item) => (
                      <FileDetail
                        file={item}
                        onRemove={() => {
                          methods.setValue(
                            `${alias}.activity_list.${index}.document`,
                            []
                          );
                        }}
                        hideDelete={mode === "detail"}
                      />
                    ))}
                </>
              ) : (
                <CustomDropzoneForm
                  disabled={mode === "detail"}
                  name={`${alias}.activity_list.${index}.document`}
                  informationText={"PNG, JPG, PDF, Docx up to 1MB"}
                  maxSize={1}
                />
              )}
            </div>
          </div>
        </div>
      ))}
      <div className="w-full flex justify-center mt-3">
        {mode !== "detail" && (
          <Button
            className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
            startIcon={<AiOutlinePlus className="text-white" />}
            onClick={() => {
              append({
                activity: "",
                description: "",
                document: [],
              });
            }}
          >
            Tambah Kegiatan
          </Button>
        )}
      </div>
    </Fragment>
  );
};

export default ActivityResult;
