import React, { useEffect, useRef, useState } from 'react';
import {
  Breadcrumbs,
  Button,
  Card,
  CardBody,
  CardForm,
  CardHeader,
  InputForm,
  InputFormUpload,
} from 'components';
import CustomSelectForm from 'pages/ActivityReport/form/Select';
import { FormProvider, useForm } from 'react-hook-form';
import InputFormDropzone from 'components/molecules/InputFormDropzone';
import { RiArrowLeftLine, RiArrowRightLine } from 'react-icons/ri';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getList } from 'services/danaProgram/investmentPlan';
import { getListPajak } from 'services/danaProgram/masterDataService';
import {
  calculateTax,
  convertMoney,
  deconvertCurrency,
  deconvertMoney,
} from 'helpers';
import { fileUpload } from 'services/danaProgram/fileService';
import {
  getDetail,
  postPembayaranResource,
} from 'services/danaProgram/pembayaranResourceService';
import { getListMainActivity } from 'services/masterData/mainActivity';
import { getListDetailActivity } from 'services/masterData/detailActivity';
import { useSelector } from 'react-redux';
import { method } from 'lodash';
import { useLocation, useNavigate } from 'react-router';
import { enqueueSnackbar } from 'notistack';
import { getListSettled } from 'services/danaProgram/accountingService';
import moment from 'moment';
import FileDetail from 'components/molecules/FileDetail';
import { getListBudgetAccount } from 'services/masterData/budgetAccount';

const validationSchema = yup.object({
  id: yup.string().nullable(),
  user_id: yup.string().required(),
  projects: yup
    .object()
    .shape({
      value: yup.string().required(),
      label: yup.string().required(),
    })
    .test('required', 'Proyek wajib diisi', (value, ctx) => {
      return !!value?.value && !!value?.label;
    })
    .nullable(),
  project_id: yup.string().required(),
  project_name: yup.string().required(),
  description: yup.string().required('Deskripsi wajib diisi'),
  transaction_date: yup
    .string()
    .required('Tanggal SPJ/Tagihan/Transaksi wajib diisi'),
  invoice_number: yup.string().required('Nomor Invoice wajib diisi'),
  project_resource_data: yup.object().shape({
    document: yup.mixed().nullable(),
    value: yup.string().required('Nilai Resource Proyek wajib diisi'),
    tax_type: yup.string().required(),
    tax_value: yup.string().required('Nilai Pajak wajib diisi'),
    billing_code: yup.string().required('Kode billing wajib diisi'),
    payment_total: yup.string().nullable(),
  }),
  tax_types: yup
    .object()
    .shape({
      value: yup.string().required(),
      label: yup.string().required(),
    })
    .test('required', 'Jenis Pajak wajib diisi', (value, ctx) => {
      return !!value?.value && !!value?.label;
    })
    .nullable(),
  payment_total: yup.number().nullable(),
  main_activity_id: yup.string().required(),
  main_activity_name: yup.string().required(),
  main_activity_obj: yup
    .object()
    .shape({
      value: yup.string().required(),
      label: yup.string().required(),
    })
    .test('required', 'Kegiatan Utama wajib diisi', (value, ctx) => {
      return !!value?.value && !!value?.label;
    })
    .nullable(),
  detail_activity_id: yup.string().required(),
  detail_activity_name: yup.string().required(),
  detail_activity_obj: yup
    .object()
    .shape({
      value: yup.string().required(),
      label: yup.string().required(),
    })
    .test('required', 'Detail Kegiatan wajib diisi', (value, ctx) => {
      return !!value?.value && !!value?.label;
    })
    .nullable(),
  currency_obj: yup
    .object()
    .shape({
      value: yup.string().required(),
      label: yup.string().required(),
    })
    .test('required', 'Mata Anggaran wajib diisi', (value, ctx) => {
      return !!value?.value && !!value?.label;
    })
    .nullable(),
  support_document: yup.mixed().nullable(),
});

const defaultValues = {
  id: null,
  user_id: '',
  project_id: '',
  project_name: '',
  description: '',
  transaction_date: '',
  invoice_number: '',
  project_resource_data: {
    document: [],
    value: '',
    tax_type: '',
    tax_value: null,
    billing_code: '',
    payment_total: null,
  },
  payment_total: 90000,
  main_activity_id: '',
  main_activity_name: '',
  detail_activity_id: '',
  detail_activity_name: '',
  currency: '',
  support_document: [],
};

const PembayaranResourcesForm = () => {
  const [payload, setPayload] = useState(null);
  const [projectOption, setProjectOption] = useState([]);
  const [pajakOption, setPajakOption] = useState([]);
  const [mainActivityOption, setMainActivityOption] = useState([]);
  const [detailActivityOption, setDetailActivityOption] = useState([]);
  const [budgetAccountOption, setBudgetAccountOption] = useState([]);

  const userSelector = useSelector((state) => state?.auth?.user || {});
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();
  const { id: idPembayaranResource } = location.state || {};

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const links = [
    {
      label: 'Daftar Pembayaran Resources Proyek',
      path: '/pembayaran-resources',
    },
    {
      label: 'Tambah Kegiatan',
    },
  ];

  const { data: dataProject } = useQuery({
    queryKey: ['get-project-from-pembayaran-resources'],
    queryFn: async () => {
      const res = await getList({ limit: 9999 });

      return res?.data?.data?.data || [];
    },
    retry: false,
    refetchOnWindowFocus: false,
  });

  const { data: dataPajak } = useQuery({
    queryKey: ['get-list-pajak-from-pembayaran-resources'],
    queryFn: async () => {
      const res = await getListPajak();

      return res?.data?.data || [];
    },
    retry: false,
    refetchOnWindowFocus: false,
  });

  const { data: dataMainActivity } = useQuery({
    queryKey: ['get-list-main-activity-from-pembayaran-resources'],
    queryFn: async () => {
      const res = await getListMainActivity();

      return res?.data?.data || [];
    },
    retry: false,
    refetchOnWindowFocus: false,
  });

  const { data: dataDetailActivity } = useQuery({
    queryKey: ['get-list-detail-activity-from-pembayaran-resources', methods.watch('main_activity_id')],
    queryFn: async () => {
      const res = await getListDetailActivity({
        id: methods.watch('main_activity_id'),
      });

      return res?.data?.data || [];
    },
    retry: false,
    refetchOnWindowFocus: false,
  });

  const { data: dataBudgetAccountSettled } = useQuery({
    queryKey: ['get-list-settled-accounting'],
    enabled: !!methods.watch('detail_activity_id'),
    queryFn: async () => {
      const res = await getListBudgetAccount({
        id: methods.watch('detail_activity_id'),
      });

      return res?.data?.data || [];
    },
    retry: false,
    refetchOnWindowFocus: false,
  });

  const { data: dataDetailPembayaranResource } = useQuery({
    queryKey: ['detail-pembayaran-resource'],
    queryFn: async () => {
      const res = await getDetail({ id: idPembayaranResource });

      return res?.data?.data || {};
    },
    enabled: !!idPembayaranResource,
    retry: false,
  });

  useEffect(() => {
    return () => {
      queryClient.setQueriesData(['detail-pembayaran-resource'], null);
    };
  }, []);

  useEffect(() => {
    if (dataProject) {
      const opt = dataProject.map((el) => ({
        value: el?._id,
        label: el?.name,
      }));

      setProjectOption(opt);
    }
  }, [dataProject]);

  useEffect(() => {
    if (dataPajak) {
      const opt = dataPajak.map((el) => ({
        value: el?.value,
        label: el?.nama,
      }));

      setPajakOption(opt);
    }
  }, [dataPajak]);

  useEffect(() => {
    if (dataMainActivity) {
      const opt = dataMainActivity.map((el) => ({
        value: el?.id,
        label: el?.kegiatanUtama,
      }));

      setMainActivityOption(opt);
    }
  }, [dataMainActivity]);

  useEffect(() => {
    if (dataDetailActivity) {
      const opt = dataDetailActivity.map((el) => ({
        value: el?.id,
        label: el?.detail,
      }));

      setDetailActivityOption(opt);
    }
  }, [dataDetailActivity]);

  useEffect(() => {
    if (dataBudgetAccountSettled) {
      const opt = dataBudgetAccountSettled.map((el) => ({
        value: el?.nama,
        label: el?.nama,
      }));

      setBudgetAccountOption(opt);
    }
  }, [dataBudgetAccountSettled]);

  useEffect(() => {
    if (dataDetailPembayaranResource && idPembayaranResource) {
      methods.reset({
        id: dataDetailPembayaranResource?._id,
        user_id: userSelector?.UserId,
        project_id: dataDetailPembayaranResource?.project_id,
        project_name: dataDetailPembayaranResource?.project_name,
        projects: {
          value: dataDetailPembayaranResource?.project_id,
          label: dataDetailPembayaranResource?.project_name,
        },
        description: dataDetailPembayaranResource?.description,
        transaction_date: dataDetailPembayaranResource?.transaction_date
          ? moment
              .utc(dataDetailPembayaranResource?.transaction_date)
              .local()
              .format('YYYY-MM-DD')
          : '',
        invoice_number: dataDetailPembayaranResource?.invoice_number,
        project_resource_data:
          dataDetailPembayaranResource?.project_resource_data,
        tax_types: pajakOption.find(
          (data) =>
            data.label ===
            dataDetailPembayaranResource?.project_resource_data?.tax_type
        ),
        payment_total: dataDetailPembayaranResource?.payment_total,
        main_activity_id: dataDetailPembayaranResource?.main_activity_id,
        main_activity_name: dataDetailPembayaranResource?.main_activity_name,
        main_activity_obj: {
          value: dataDetailPembayaranResource?.main_activity_id,
          label: dataDetailPembayaranResource?.main_activity_name,
        },
        detail_activity_id: dataDetailPembayaranResource?.detail_activity_id,
        detail_activity_name:
          dataDetailPembayaranResource?.detail_activity_name,
        detail_activity_obj: {
          value: dataDetailPembayaranResource?.detail_activity_id,
          label: dataDetailPembayaranResource?.detail_activity_name,
        },
        currency: dataDetailPembayaranResource?.currency,
        currency_obj: {
          value: dataDetailPembayaranResource?.currency,
          label: dataDetailPembayaranResource?.currency,
        },
        support_document: dataDetailPembayaranResource?.support_document,
      });
    }
  }, [dataDetailPembayaranResource, pajakOption]);

  useEffect(() => {
    if (userSelector) {
      methods.setValue('user_id', userSelector?.UserId);
    }
  }, [userSelector]);

  const onSubmit = (data) => {
    // deleting payload dropdown field which isn't needed
    delete data['projects'];
    delete data['tax_types'];
    delete data['main_activity_obj'];
    delete data['detail_activity_obj'];
    delete data['currency_obj'];

    //remove char at format currency for payload
    data.project_resource_data.value = data.project_resource_data.value.replace(
      /\D/,
      ''
    );
    data.project_resource_data.tax_value =
      data.project_resource_data.tax_value.replace(/\D/, '');
    data.project_resource_data.payment_total =
      data.project_resource_data.payment_total.replace(/\D/, '');

    setPayload(data);
    if (
      data?.support_document.every((doc) => doc instanceof File) ||
      data?.project_resource_data?.document.every((doc) => doc instanceof File)
    ) {
      postFile.mutate({
        support_document: data?.support_document,
        project_resource_data_document: data?.project_resource_data?.document,
      });
    } else {
      postSubmit.mutate(data);
    }
  };

  const postFile = useMutation({
    mutationKey: ['post-files-pembayaran-resource'],
    mutationFn: async (files) => {
      const promise = Object.entries(files).map(async (entry, i) => {
        return Promise.all(
          entry[1].map(async (doc) => {
            let formData = new FormData();
            formData.append('file', doc);
            const res = await fileUpload(formData);
            return {
              fileId: res.data.data.id,
              fileName: res.data.data.name,
              fileSize: res.data.data.size,
              mimeType: res.data.data.mimeType,
              path: res.data.data.key,
            };
          })
        ).then((data) => {
          return {
            [entry[0]]: data,
          };
        });
      });

      return Promise.all(promise);
    },
    onSuccess: async (data) => {
      let payloadData = {
        ...payload,
        support_document: data?.[0].support_document,
        project_resource_data: {
          ...payload.project_resource_data,
          document: data?.[1]?.project_resource_data_document,
        },
      };

      postSubmit.mutateAsync(payloadData);
    },
  });

  const postSubmit = useMutation({
    mutationKey: ['post-submit-pembayaran-resource'],
    mutationFn: async (payload) => {
      const res = await postPembayaranResource(payload);

      return res;
    },
    onSuccess: async () => {
      enqueueSnackbar({
        message: 'Data berhasil disimpan',
        variant: 'success',
      });
      navigate('/pembayaran-resources');
    },
  });

  const convertCurrency = (value) => {
    let oriValue = String(value);
    oriValue = oriValue.includes('.') ? oriValue.split('.').join('') : oriValue;
    let modifValue = '';
    let count = 1;
    if (oriValue !== '' && oriValue.length > 3) {
      const splitValue = oriValue.split('');
      for (let i = splitValue.length - 1; i >= 0; i--) {
        if (count % 3 === 0 && count !== 0 && i !== 0) {
          modifValue = `.${splitValue[i]}${modifValue}`;
        } else {
          modifValue = `${splitValue[i]}${modifValue}`;
        }
        count += 1;
      }
    } else {
      modifValue = oriValue;
    }
    return modifValue;
  };

  const { watch, setValue } = methods;

  useEffect(() => {
    if (
      typeof watch('project_resource_data.value') === 'string' ||
      typeof watch('project_resource_data.tax_value') === 'string'
    ) {
      let val = watch('project_resource_data.value')?.replace(/\D/g, '') ?? '';
      let taxVal =
        watch('project_resource_data.tax_value')?.replace(/\D/g, '') ?? '';

      methods.setValue(
        'project_resource_data.payment_total',
        convertMoney(val - taxVal)
      );

      methods.setValue('payment_total', val - taxVal);
    }
  }, [
    watch('project_resource_data.tax_value'),
    watch('project_resource_data.value'),
  ]);

  useEffect(() => {
    if (watch('tax_types')) {
      let obj = watch('tax_types');
      methods.setValue(
        'project_resource_data.tax_value',
        convertMoney(
          deconvertMoney(watch('project_resource_data.value')) *
            (obj?.value / 100)
        )
      );
    }
    let value = watch('project_resource_data.value');
    methods.setValue(`project_resource_data.value`, convertMoney(value));
  }, [watch('project_resource_data.value')]);

  useEffect(() => {
    let obj = watch('tax_types');
    methods.setValue(
      'project_resource_data.tax_value',
      convertMoney(
        deconvertMoney(watch('project_resource_data.value')) *
          (obj?.value / 100)
      )
    );
  }, [watch('tax_types')]);

  return (
    <FormProvider {...methods}>
      <div className="flex flex-col gap-8">
        <Breadcrumbs items={links} />
        <Card>
          <CardHeader>
            <div>Pengajuan Pembayaran Resources Proyek</div>
          </CardHeader>
          <CardBody>
            <div className="space-y-5">
              <div>
                <label className="label font-semibold text-[14px]">
                  <span className={`label-text`}>
                    Nama Proyek
                    <span className="text-error-500 text-sm">*</span>
                  </span>
                </label>
                <CustomSelectForm
                  name="projects"
                  placeholder=""
                  options={projectOption}
                  onChange={(e) => {
                    methods.setValue('projects', e);
                    methods.setValue('project_id', e.value);
                    methods.setValue('project_name', e.label);
                  }}
                />
              </div>
              <InputForm
                controllerName={`description`}
                className={`py-2 px-4 mt-2 border w-full rounded-md focus-within:border-primary-700`}
                label={'Uraian Pengujian'}
                placeholder={''}
                rows={5}
                textArea
                required
              />
              <div className="flex w-2/3 space-x-4">
                <InputForm
                  controllerName={`transaction_date`}
                  className={`w-full`}
                  label={'Tanggal SPJ/ Tagihan/ Transaksi'}
                  type="date"
                  required
                />
                <InputForm
                  controllerName={`invoice_number`}
                  className={`w-full`}
                  label={'Nomor Invoice'}
                  required
                />
              </div>
            </div>
            <div className={`flex rounded-lg border-[1px] px-6 py-5 mt-5`}>
              <div className={`flex flex-col w-full `}>
                <div className={`flex items-center bg-[#E1F1D6] rounded-lg`}>
                  <div className="flex-1 p-4 space-x-2">
                    <span className={'text-[#01A24A] font-[600]'}>
                      Data Resources Proyek
                    </span>
                    <span className="bg-primary-50 text-xs p-2 rounded font-semibold">
                      Total Nilai Transaksi:{' '}
                      {convertCurrency(
                        methods.watch('project_resource_data.payment_total')
                      )}
                    </span>
                  </div>
                </div>
                <div className="py-5 space-y-5">
                  <div className="space-y-2">
                    <div className="flex text-[#1D2939] font-semibold text-sm">
                      Dokumen Daftar Nominatif Resources Proyek yang akan
                      dibayar
                      <span className="text-error-500 text-sm">*</span>
                    </div>
                    {dataDetailPembayaranResource?.project_resource_data
                      ?.document?.length > 0 ? (
                      dataDetailPembayaranResource?.project_resource_data?.document?.map(
                        (file, key) => (
                          <FileDetail
                            key={'project-resource-data-document-' + key}
                            file={file}
                            hideDelete
                          />
                        )
                      )
                    ) : (
                      <InputFormDropzone
                        name="project_resource_data.document"
                        accept={{
                          'image/png': [],
                          'image/jpeg': [],
                          'application/pdf': [],
                          'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                            [],
                        }}
                        maxFiles={10}
                        maxSize={10}
                        multiple={true}
                        informationText="PNG, JPG, PDF, Docx"
                      />
                    )}
                  </div>
                  <div className="grid grid-cols-4 w-full gap-2">
                    <div className={`w-full`}>
                      <InputForm
                        controllerName={`project_resource_data.value`}
                        className={`w-full`}
                        label={'Nilai (Rp)'}
                        prefix={'Rp.'}
                        required
                        // onChangeInput={(e) => {
                        //   methods.setValue(
                        //     `project_resource_data.value`,
                        //     convertMoney(e.target.value)
                        //   );
                        // }}
                      />
                    </div>
                    <div className={`w-full`}>
                      <label className="label font-semibold text-[14px] pt-0 pb-1">
                        <span className={`label-text`}>
                          Pajak
                          <span className="text-error-500 text-sm">*</span>
                        </span>
                      </label>
                      <CustomSelectForm
                        name={`tax_types`}
                        options={pajakOption}
                        className={`w-full h-full`}
                        onChange={(e) => {
                          methods.setValue('tax_types', e);
                          methods.setValue(
                            'project_resource_data.tax_type',
                            e.label
                          );
                        }}
                      />
                    </div>
                    <div className={`w-full`}>
                      <InputForm
                        controllerName={`project_resource_data.tax_value`}
                        className={`w-full`}
                        label={'Nilai Pajak (Rp)'}
                        required
                        prefix={'Rp.'}
                        disabled
                        // onChangeInput={(e) => {
                        //   methods.setValue(
                        //     `project_resource_data.tax_value`,
                        //     convertCurrency(e.target.value)
                        //   )
                        // }}
                      />
                    </div>
                    <div className={`w-full`}>
                      <InputForm
                        controllerName={`project_resource_data.billing_code`}
                        className={`w-full`}
                        label={'Kode Billing'}
                        required
                      />
                    </div>
                    <div className={`w-full`}>
                      <InputForm
                        controllerName={`project_resource_data.payment_total`}
                        className={`w-full`}
                        label={'Total Pembayaran (Rp)'}
                        required
                        disabled
                        prefix={'Rp.'}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </CardBody>
          <CardBody>
            <div className={`space-y-5`}>
              <div>
                <label className="label font-semibold text-[14px]">
                  <span className={`label-text`}>
                    Kegiatan Utama
                    <span className="text-error-500 text-sm">*</span>
                  </span>
                </label>
                <CustomSelectForm
                  name="main_activity_obj"
                  placeholder=""
                  options={mainActivityOption}
                  onChange={(e) => {
                    methods.setValue('main_activity_obj', e);
                    methods.setValue(`main_activity_id`, e.value);
                    methods.setValue(`main_activity_name`, e.label);
                  }}
                />
              </div>
              <div>
                <label className="label font-semibold text-[14px]">
                  <span className={`label-text`}>
                    Detail Kegiatan
                    <span className="text-error-500 text-sm">*</span>
                  </span>
                </label>
                <CustomSelectForm
                  name="detail_activity_obj"
                  placeholder=""
                  options={detailActivityOption}
                  onChange={(e) => {
                    methods.setValue('detail_activity_obj', e);
                    methods.setValue(`detail_activity_id`, e.value);
                    methods.setValue(`detail_activity_name`, e.label);
                  }}
                />
              </div>
              <div>
                <label className="label font-semibold text-[14px]">
                  <span className={`label-text`}>
                    Mata Anggaran
                    <span className="text-error-500 text-sm">*</span>
                  </span>
                </label>
                <CustomSelectForm
                  name="currency_obj"
                  placeholder=""
                  options={budgetAccountOption}
                  onChange={(e) => {
                    methods.setValue('currency_obj', e);
                    methods.setValue(`currency`, e.label);
                  }}
                />
              </div>
            </div>
          </CardBody>
        </Card>
        <Card>
          <CardHeader>
            <div>Dokumen Bukti Pendukung</div>
          </CardHeader>
          <CardBody>
            <div className="space-y-2">
              <div className="text-[#1D2939] font-semibold text-sm">
                Dokumen Bukti Pendukung
              </div>
              {dataDetailPembayaranResource?.support_document?.length > 0 ? (
                dataDetailPembayaranResource?.support_document?.map(
                  (file, key) => (
                    <FileDetail
                      key={'support-document-pembayaran-resource-' + key}
                      file={file}
                      hideDelete
                    />
                  )
                )
              ) : (
                <InputFormDropzone
                  name="support_document"
                  accept={{
                    'image/png': [],
                    'image/jpeg': [],
                    'application/pdf': [],
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                      [],
                  }}
                  maxFiles={10}
                  maxSize={10}
                  multiple={true}
                  informationText="PNG, JPG, PDF, Docx"
                />
              )}
            </div>
          </CardBody>
        </Card>
      </div>
      <div className="block md:flex items-center justify-between px-10 py-5 border-[2px] border-gray-200 rounded-3xl mt-4 bg-white mb-10">
        <Button
          type="button"
          className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300 w-40"
          onClick={() => navigate(-1)}
        >
          Batal
        </Button>
        <Button
          type="button"
          className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
          onClick={() => methods.handleSubmit(onSubmit)()}
        >
          Submit
        </Button>
      </div>
    </FormProvider>
  );
};

export default PembayaranResourcesForm;
