/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from "react";
import {
  AtomDatePicker,
  Breadcrumbs,
  Button,
  Card,
  CardBody,
  CardForm,
  CardHeader,
  Input,
} from "components";
import ProgressStepForm from "components/molecules/ProgressStepForm";
import { FormProvider, useForm } from "react-hook-form";
import { FaPlus, FaTrashAlt } from "react-icons/fa";
import { FiAlertTriangle } from "react-icons/fi";
import { RiArrowLeftLine, RiArrowRightLine, RiSave3Line } from "react-icons/ri";
import { useLocation, useNavigate } from "react-router";
import _ from "lodash";
import axios from "axios";
import config from "app/config";
import { UseAnnualWorkPlanContext } from "../../../context/AnnualWorkPlanContext";
import SelectProvince from "components/organisms/Inputs/SelectProvince";
import SelectCity from "components/organisms/Inputs/SelectCity";
import SelectDistrict from "components/organisms/Inputs/SelectDistrict";
import SelectVillage from "components/organisms/Inputs/SelectVillage";
import Select from "react-select";
import { useQuery } from "@tanstack/react-query";
import { getListDetailActivity } from "services/masterData/detailActivity";
import axiosInstance from "app/interceptors";
import { getListPenerimaManfaat } from "services/masterData/penerimaManfaat";
import { ConfirmationModal } from "components/molecules/Modal";

const AddAWPDetailActivity = () => {
  const initialMainActivity = {
    "": [],
  };
  const [activity, setActivity] = useState(initialMainActivity);
  const [activityIds, setActivityIds] = useState([]);
  const [detailAWP, setDetailAWP] = useState(null);
  const [formIsError, setFormIsError] = useState(true);
  const [penerimaManfaatOpt, setPenerimaManfaatOpt] = useState([]);
  const [confirmModal, setConfirmModal] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const methods = useForm();
  const {
    idAwp,
    setData,
    setCurrentStep,
    currentStep,
    dataAnnualWorkPlan,
    type,
  } = UseAnnualWorkPlanContext();

  const links = [
    {
      label: "Pengajuan Dana",
      path: "/",
    },
    {
      label: "Annual Work Plan",
      path: "/annual-work-plan",
    },
    {
      label: "Tambah Permohonan",
    },
  ];
  useEffect(() => {
    fetchDetailAWP();
    fetchPenerimaManfaat();
  }, []);

  useEffect(() => {
    if (!_.isEmpty(detailAWP?.output)) {
      let empty = {};
      let activityIdsTmp = [...activityIds];

      _.map(detailAWP.output, (output, key) => {
        _.map(output.activity, (activity) => {
          if (!activityIdsTmp.includes(activity?.main_activity_id)) {
            activityIdsTmp.push(activity?.main_activity_id);
          }

          if (activity?.detail?.length) {
          }
          empty[activity.name + ` (${activity._id})`] = !activity?.detail
            ?.length
            ? [
                {
                  detail_activity_id: "",
                  detailActivity: "",
                  isError: false,
                  message: "",
                  province: {},
                  cities: {},
                  district: {},
                  subdistrict: {},
                  implementationPlanTime: [
                    {
                      date: null,
                      responsiblePerson: "",
                      isError: false,
                      message: "",
                    },
                  ],
                },
              ]
            : activity?.detail?.map((detail) => ({
                detail_id: detail?._id,
                detail_activity_id: detail?.detail_activity_id,
                detailActivity: detail?.name,
                main_activity_id: activity?.main_activity_id,
                isError: false,
                message: "",
                province: detail?.province,
                cities: detail?.cities,
                district: detail?.district,
                subdistrict: detail?.subdistrict,
                implementationPlanTime: !detail?.execution?.length
                  ? [
                      {
                        date: null,
                        responsiblePerson: "",
                        isError: false,
                        message: "",
                      },
                    ]
                  : _.map(detail.execution, (execution) => ({
                      date: execution.execution_date,
                      responsiblePerson: execution.contact_person ?? {},
                      isError: false,
                      message: "",
                    })),
              }));
        });
      });
      setActivity(empty);
      setActivityIds(activityIdsTmp);
    }
  }, [detailAWP]);

  // use this if condition show detail
  useEffect(() => {
    if (!_.isEmpty(dataAnnualWorkPlan?.output)) {
      let empty = {};

      _.map(dataAnnualWorkPlan?.output, (output, key) => {
        _.map(output.activity, (activity) => {
          empty[activity.name + ` (${activity._id})`] = !activity?.detail
            ?.length
            ? [
                {
                  detail_activity_id: "",
                  detailActivity: "",
                  isError: false,
                  message: "",
                  province: {},
                  cities: {},
                  implementationPlanTime: [
                    {
                      date: null,
                      responsiblePerson: "",
                      isError: false,
                      message: "",
                    },
                  ],
                },
              ]
            : activity.detail.map((detail) => ({
                detail_activity_id: detail?.detail_activity_id,
                detailActivity: detail?.name,
                main_activity_id: activity?.main_activity_id,
                isError: false,
                message: "",
                province: detail?.province,
                cities: detail?.cities,
                implementationPlanTime: !detail?.execution?.length
                  ? [
                      {
                        date: null,
                        responsiblePerson: "",
                        isError: false,
                        message: "",
                      },
                    ]
                  : _.map(detail.execution, (execution) => ({
                      date: execution.execution_date,
                      responsiblePerson: execution.contact_person ?? {},
                      isError: false,
                      message: "",
                    })),
              }));
        });
      });

      setActivity(empty);
    }
  }, [dataAnnualWorkPlan]);

  const onChangeProvince = (value, label, childKey) => {
    let tempState = activity[label];
    tempState[childKey].province = { id: value.value, name: value.label };

    setActivity((prev) => ({
      ...prev,
      [label]: tempState,
    }));
  };

  const onChangeCity = (value, label, childKey) => {
    let tempState = activity[label];
    tempState[childKey].cities = { id: value.value, name: value.label };

    setActivity((prev) => ({
      ...prev,
      [label]: tempState,
    }));
  };

  const onChangeDistrict = (value, label, childKey) => {
    let tempState = activity[label];
    tempState[childKey].district = { id: value.value, name: value.label };

    setActivity((prev) => ({
      ...prev,
      [label]: tempState,
    }));
  };

  const onChangeSubdistrict = (value, label, childKey) => {
    let tempState = activity[label];
    tempState[childKey].subdistrict = { id: value.value, name: value.label };

    setActivity((prev) => ({
      ...prev,
      [label]: tempState,
    }));
  };

  const onChangeDetailTitle = (e, label, childKey) => {
    let tempState = activity[label];
    tempState[childKey].detail_activity_id = e.value;
    tempState[childKey].detailActivity = e?.label;

    // Add validation
    if (
      tempState[childKey]?.detailActivity?.length < 5 &&
      tempState[childKey].detailActivity !== ""
    ) {
      tempState[childKey].isError = true;
      tempState[childKey].message =
        "Detail/Sub kegiatan kurang dari 5 karakter";
    } else {
      if (tempState[childKey]?.detailActivity?.length > 255) {
        tempState[childKey].isError = true;
        tempState[childKey].message =
          "Detail/Sub kegiatan lebih dari 255 karakter";
      } else {
        tempState[childKey].isError = false;
        tempState[childKey].message = "";
      }
    }

    if (/^\s*$/.test(tempState[childKey].detailActivity)) {
      tempState[childKey].isError = true;
    }

    if (tempState[childKey].detailActivity === "") {
      tempState[childKey].isError = true;
      tempState[childKey].message = "Detail/Sub kegiatan wajib diisi";
    }

    setActivity((prev) => ({
      ...prev,
      [label]: tempState,
    }));
  };

  const onChangePlanDate = (value, label, childKey, planKey) => {
    let temp = { ...activity };
    temp[label][childKey].implementationPlanTime[planKey].date = value;
    temp[label][childKey].implementationPlanTime[planKey].isErrorPlan = false;
    temp[label][childKey].implementationPlanTime[planKey].messagePlan = "";

    setActivity(temp);
  };

  const onChangeResponsiblePerson = (e, label, childKey, planKey) => {
    let temp = { ...activity };
    temp[label][childKey].implementationPlanTime[planKey].responsiblePerson = e;

    // Add validation
    if (
      temp[label][childKey].implementationPlanTime[planKey].responsiblePerson
        ?.length < 5
    ) {
      temp[label][childKey].implementationPlanTime[planKey].isError = true;
      temp[label][childKey].implementationPlanTime[planKey].message =
        "Penanggung jawab kurang dari 5 karakter";
    } else {
      if (
        temp[label][childKey].implementationPlanTime[planKey].responsiblePerson
          ?.length > 50
      ) {
        temp[label][childKey].implementationPlanTime[planKey].isError = true;
        temp[label][childKey].implementationPlanTime[planKey].message =
          "Penanggung jawab lebih dari 50 karakter";
      } else {
        temp[label][childKey].implementationPlanTime[planKey].isError = false;
        temp[label][childKey].implementationPlanTime[planKey].message = "";
      }
    }

    if (
      temp[label][childKey].implementationPlanTime[planKey]
        .responsiblePerson === ""
    ) {
      temp[label][childKey].implementationPlanTime[planKey].isError = true;
      temp[label][childKey].implementationPlanTime[planKey].message =
        "Penanggung jawab wajib diisi";
    }

    if (
      /^\s*$/.test(
        temp[label][childKey].implementationPlanTime[planKey].responsiblePerson
      )
    ) {
      temp[label][childKey].implementationPlanTime[planKey].isError = true;
    }

    setActivity(temp);
  };

  const deletePlanTimeHandler = (label, childKey, planKey) => {
    let temp = { ...activity };
    let arr = temp[label][childKey].implementationPlanTime.filter(
      (_, i) => i !== planKey
    );
    temp[label][childKey].implementationPlanTime = arr;

    setActivity(temp);
  };

  const addPlanTimeHandler = (label, childKey) => {
    let temp = { ...activity };
    temp[label][childKey].implementationPlanTime.push({
      date: null,
      responsiblePerson: "",
      isError: false,
      message: "",
    });

    setActivity(temp);
  };

  const addActivityHandler = (label) => {
    let temp = { ...activity };
    temp[label].push({
      detail_activity_id: "",
      detailActivity: "",
      implementationPlanTime: [
        {
          date: null,
          responsiblePerson: "",
          isError: false,
          message: "",
        },
      ],
      isError: false,
      message: "",
    });

    setActivity(temp);
  };

  const deleteParentActivityHandler = (label, childKey) => {
    let temp = { ...activity };
    let arr = temp[label].filter((item, key) => key !== childKey);
    temp[label] = arr;

    setActivity(temp);
  };

  //detecting validation at activity array
  useEffect(() => {
    let arr = [];
    _.map(Object.keys(activity), (label) => {
      _.map(activity[label], (detail) => {
        arr.push(detail);
      });
    });

    if (arr?.length > 0) {
      // if not detecting error validation or empty value
      if (
        !_.filter(arr, (x) => x.isError === true || x.detailActivity === "")
          ?.length
      ) {
        setFormIsError(false);
      } else {
        setFormIsError(true);
      }
    }
  }, [
    activity,
    onChangeDetailTitle,
    addActivityHandler,
    deleteParentActivityHandler,
    onChangePlanDate,
    deletePlanTimeHandler,
  ]);

  //detecting validation at plan array
  useEffect(() => {
    let arr = [];
    _.map(Object.keys(activity), (label) => {
      _.map(activity[label], (detail) => {
        _.map(detail.implementationPlanTime, (plan) => {
          arr.push(plan);
        });
      });
    });

    if (arr?.length > 0) {
      // if not detecting error validation or empty value
      if (
        _.filter(
          arr,
          (x) => x.isError === true || x.responsiblePerson === "" || !x.date
        )?.length
      ) {
        setFormIsError(true);
      } else {
        setFormIsError(false);
      }
    }
  }, [
    activity,
    onChangeResponsiblePerson,
    onChangePlanDate,
    addPlanTimeHandler,
  ]);

  const fetchDetailAWP = async () => {
    const response = await axiosInstance.get(
      `${config.BASE_URL}/api/dana-program/v1/cms/awp?id=${idAwp}`
    );
    const data = await response.data.data;
    setDetailAWP(data);
  };

  const fetchPenerimaManfaat = async () => {
    const response = await getListPenerimaManfaat();
    const data = await response?.data?.data;

    const temp = data?.map((el) => ({
      value: el?.nama,
      label: el?.nama,
    }));

    setPenerimaManfaatOpt(temp);
  };

  const { data: dataDetailActivity } = useQuery({
    queryKey: ["application-get-detail-activity"],
    enabled: activityIds.length > 0,
    queryFn: async () => {
      const data = {};
      const promises = await Promise.all(
        activityIds.map(async (id) => await getListDetailActivity({ id }))
      );

      promises.forEach((el) => {
        data[el.config.params?.id] = el?.data?.data?.map((el) => ({
          value: el?.id,
          label: el?.detail,
        }));
      });

      return data;
    },
  });

  const onSubmit = async (draft = false) => {
    if (type === "show") {
      setCurrentStep({ currentStep: "4" });
    } else {
      try {
        let tampActivityIfIsError = { ...activity };
        const payload = {
          id: detailAWP?._id,
          detail_activity: _.map(Object.keys(activity), (label) => ({
            awp_activity_id: label.match(/\(([^)]+)\)/)[1],
            data: _.map(activity[label], (data, index) => {
              const detailActivity = data.detailActivity;
              const arrImplementationPlanTime = data.implementationPlanTime;
              if (!detailActivity) {
                tampActivityIfIsError[label][index] = {
                  ...data,
                  isError: true,
                  message: "Detail/Sub kegiatan wajib diisi",
                };
              }

              if (arrImplementationPlanTime.length) {
                arrImplementationPlanTime?.forEach?.((plan, planIndex) => {
                  let obj = {};
                  if (!plan.date) {
                    // tampActivityIfIsError[label][index].implementationPlanTime[planIndex] = {
                    //   ...plan,
                    //   isErrorPlan: true,
                    //   messagePlan: "Rencana waktu pelaksanaan wajib diisi",
                    // };

                    obj = {
                      ...plan,
                      isErrorPlan: true,
                      messagePlan: "Rencana waktu pelaksanaan wajib diisi",
                    };
                  } else {
                    obj = {
                      ...plan,
                      isErrorPlan: false,
                      messagePlan: "",
                    };
                  }

                  if (!plan.responsiblePerson) {
                    // tampActivityIfIsError[label][index].implementationPlanTime[planIndex] = {
                    //   ...plan,
                    //   isError: true,
                    //   message: "Penanggung jawab wajib diisi",
                    // };

                    obj = {
                      ...obj,
                      ...plan,
                      isError: true,
                      message: "Penanggung jawab wajib diisi",
                    };
                  } else {
                    obj = {
                      ...plan,
                      ...obj,
                      isError: false,
                      message: "",
                    };
                  }

                  tampActivityIfIsError[label][index].implementationPlanTime[
                    planIndex
                  ] = obj;
                });
              }

              return {
                detail_id: data.detail_id,
                detail_activity_id: data.detail_activity_id,
                name: data.detailActivity,
                execution: _.map(data.implementationPlanTime, (execution) => ({
                  execution_date: execution.date,
                  contact_person: execution.responsiblePerson,
                })),
                province: data.province,
                cities: data.cities,
                district: data.district,
                subdistrict: data.subdistrict,
              };
            }),
          })),
        };

        let isContainError = false;

        _.map(Object.keys(tampActivityIfIsError), (label) => {
          _.map(tampActivityIfIsError[label], (detail) => {
            if (detail.isError) {
              isContainError = true;
            }

            _.map(detail.implementationPlanTime, (plan) => {
              if (plan.isError) {
                isContainError = true;
              } else if (plan.isErrorPlan) {
                isContainError = true;
              }

            });
          });
        });
        
        if(!isContainError) {
          const response = await axiosInstance.post(
            `${config.BASE_URL}/api/dana-program/v1/cms/awp/store/third-step`,
            payload
          );
  
          if (response?.data?.code === 200) {
            if (draft) {
              navigate("/annual-work-plan");
            } else {
              setCurrentStep({ currentStep: "4" });
            }
          }
        } else {
          setActivity(tampActivityIfIsError);
        }

        
      } catch (err) {
        console.log(err);
      }
    }
  };

  const step = Number(location.pathname.split("/")[3]);

  const stepForm = [
    "General Informasi",
    "Kegiatan Utama",
    "Detail/Sub Kegiatan",
    "Anggaran Biaya",
    "Dokumen Pendukung",
  ];

  const renderModal = () => {
    return (
      <ConfirmationModal
        open={confirmModal}
        onClose={() => setConfirmModal(!confirmModal)}
        onSubmit={() => {
          onSubmit(true);
        }}
      >
        Apakah anda akan menyimpan sebagai draft?
      </ConfirmationModal>
    );
  };

  return (
    <div className="flex flex-col gap-8">
      {/* Card Content */}
      <FormProvider {...methods}>
        <div className="flex flex-col gap-4">
          <Card>
            <CardHeader>Detail/Sub Kegiatan</CardHeader>
            <CardBody>
              <div className="flex flex-col gap-4">
                {/** Start of Main Activity */}
                {_.map(Object.keys(activity), (label, parentKey) => (
                  <CardForm
                    key={"main-activity-" + parentKey}
                    buttonCollapse
                    label={`Kegiatan Utama ${parentKey + 1} - ${label.replace(
                      /\s*\([^)]*\)(?!.*\()/,
                      ""
                    )}`}
                  >
                    {/* Detail Kegiatan */}
                    {_.map(activity[label], (detail, childKey) => {
                      return (
                        <Card key={"detail-activity-" + childKey}>
                          <CardForm
                            buttonCollapse
                            label={`Detail/Sub Kegiatan ${childKey + 1}`}
                            actionButton={
                              activity[label]?.length > 1 && childKey > 0 ? (
                                <Button
                                  className="bg-[#D92D20] text-white"
                                  startIcon={<FaTrashAlt />}
                                  onClick={() =>
                                    deleteParentActivityHandler(label, childKey)
                                  }
                                  disabled={type === "show"}
                                />
                              ) : (
                                <></>
                              )
                            }
                          >
                            <div className="flex flex-col w-full gap-6">
                              <div className="space-y-[9px]">
                                <label className="text-[#1D2939] font-semibold text-sm">
                                  Detail/Sub Kegiatan
                                  <span className="ml-1 text-error-500 text-sm">
                                    *
                                  </span>
                                </label>
                                <Select
                                  className="w-full text-sm remove-input-txt-border-react-select"
                                  options={dataDetailActivity?.[detail?.main_activity_id] ?? []}
                                  value={
                                    typeof detail.detailActivity === "string"
                                      ? {
                                          value: detail.detailActivity,
                                          label: detail.detailActivity,
                                        }
                                      : detail.detailActivity
                                  }
                                  onChange={(e) => {
                                    onChangeDetailTitle(e, label, childKey);
                                  }}
                                  isDisabled={type === "show"}
                                />
                                {detail.isError && (
                                  <span className="text-error-500 text-xs">
                                    {detail.message}
                                  </span>
                                )}
                              </div>
                              <div className="grid grid-cols-2 gap-5">
                                <div>
                                  <label className="text-[#1D2939] font-semibold text-sm">
                                    Provinsi
                                  </label>
                                  <SelectProvince
                                    value={{
                                      value: detail?.province?.id,
                                      label: detail?.province?.name,
                                    }}
                                    onChange={(e) =>
                                      onChangeProvince(e, label, childKey)
                                    }
                                    disabled={type === "show"}
                                  />
                                </div>
                                <div>
                                  <label className="text-[#1D2939] font-semibold text-sm">
                                    Kabupaten/Kota
                                  </label>
                                  <SelectCity
                                    value={{
                                      value: detail?.cities?.id,
                                      label: detail?.cities?.name,
                                    }}
                                    provinceId={
                                      activity[label][childKey]?.province?.id ||
                                      ""
                                    }
                                    onChange={(e) =>
                                      onChangeCity(e, label, childKey)
                                    }
                                    disabled={type === "show"}
                                  />
                                </div>
                              </div>
                              {/* Kecamatan & Kelurahan */}
                              <div className="grid grid-cols-2 gap-5">
                                <div>
                                  <label className="text-[#1D2939] font-semibold text-sm">
                                    Kecamatan
                                  </label>
                                  <SelectDistrict
                                    value={{
                                      value: detail?.district?.id,
                                      label: detail?.district?.name,
                                    }}
                                    cityId={
                                      activity[label][childKey]?.cities?.id ||
                                      ""
                                    }
                                    onChange={(e) =>
                                      onChangeDistrict(e, label, childKey)
                                    }
                                    disabled={type === "show"}
                                  />
                                </div>
                                <div>
                                  <label className="text-[#1D2939] font-semibold text-sm">
                                    Kelurahan/Desa
                                  </label>
                                  <SelectVillage
                                    value={{
                                      value: detail?.subdistrict?.id,
                                      label: detail?.subdistrict?.name,
                                    }}
                                    districtId={
                                      activity[label][childKey]?.district?.id ||
                                      ""
                                    }
                                    onChange={(e) =>
                                      onChangeSubdistrict(e, label, childKey)
                                    }
                                    disabled={type === "show"}
                                  />
                                </div>
                              </div>
                              {/* End of Kecamatan & Kelurahan */}
                              {_.map(
                                detail.implementationPlanTime,
                                (plan, planKey) => {
                                  return (
                                    <div
                                      key={"plan-time-" + planKey}
                                      className="flex w-full gap-6"
                                    >
                                      <div className="flex flex-col gap-6 w-[50%]">
                                        <label className="text-[#1D2939] font-semibold text-sm">
                                          Rencana Waktu Pelaksanaan
                                          <span className="ml-1 text-error-500 text-sm">
                                            *
                                          </span>
                                        </label>
                                        <div className="">
                                          <Input
                                            type="month"
                                            className={
                                              "max-h-[38px] mt-0 rounded-md border-[#667085] border-solid text-sm outline-none focus:outline-none w-full"
                                            }
                                            value={plan.date}
                                            onChange={({ target: { value } }) =>
                                              onChangePlanDate(
                                                value,
                                                label,
                                                childKey,
                                                planKey
                                              )
                                            }
                                            disabled={type === "show"}
                                          />
                                          {plan.isErrorPlan && (
                                            <span className="text-error-500 text-xs">
                                              {plan.messagePlan}
                                            </span>
                                          )}
                                        </div>
                                      </div>
                                      <div className="w-[40.5%] space-y-5">
                                        <label className="text-[#1D2939] font-semibold text-sm">
                                          Penanggung Jawab
                                          <span className="ml-1 text-error-500 text-sm">
                                            *
                                          </span>
                                        </label>
                                        <Select
                                          className={`w-full text-sm border`}
                                          options={penerimaManfaatOpt}
                                          value={plan.responsiblePerson || null}
                                          onChange={(e) =>
                                            onChangeResponsiblePerson(
                                              e,
                                              label,
                                              childKey,
                                              planKey
                                            )
                                          }
                                          isDisabled={type === "show"}
                                        />
                                        {plan.isError && (
                                          <span className="text-error-500 text-xs">
                                            {plan.message}
                                          </span>
                                        )}
                                      </div>
                                      <div className="flex w-[5%] justify-end mt-9">
                                        {planKey !== 0 && (
                                          <Button
                                            className="bg-[#D92D20] text-white"
                                            startIcon={<FaTrashAlt />}
                                            onClick={() =>
                                              deletePlanTimeHandler(
                                                label,
                                                childKey,
                                                planKey
                                              )
                                            }
                                            disabled={type === "show"}
                                          />
                                        )}
                                      </div>
                                    </div>
                                  );
                                }
                              )}

                              <hr />

                              <div className="flex justify-end">
                                <Button
                                  type="button"
                                  className="bg-white border-[#D0D5DD]"
                                  startIcon={<FaPlus />}
                                  onClick={() =>
                                    addPlanTimeHandler(label, childKey)
                                  }
                                  disabled={type === "show"}
                                >
                                  Rencana Waktu Pelaksanaan
                                </Button>
                              </div>
                            </div>
                          </CardForm>
                        </Card>
                      );
                    })}

                    <hr />

                    <div className="flex items-center justify-center">
                      <Button
                        type="button"
                        className="bg-white border-[#D0D5DD]"
                        startIcon={<FaPlus />}
                        onClick={() => addActivityHandler(label)}
                        disabled={type === "show"}
                      >
                        Tambah Detail/Sub Kegiatan
                      </Button>
                    </div>
                  </CardForm>
                ))}
                {/** End of Main Activity */}
              </div>
            </CardBody>
          </Card>
        </div>
      </FormProvider>

      {/* Button */}
      <div className="block md:flex items-center justify-between px-6 py-5 border border-gray-200 rounded-lg mt-4 bg-white mb-10">
        <div>
          <Button
            type="button"
            className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300 w-40"
            onClick={() => setCurrentStep({ currentStep: "2" })}
          >
            <RiArrowLeftLine />
            Kembali
          </Button>
        </div>
        <div className="block md:flex items-center justify-between gap-2">
          {type !== "show" && (
            <Button
              type="button"
              onClick={() => setConfirmModal(true)}
              // disabled={formIsError}
            >
              <RiSave3Line />
              Simpan & Draft
            </Button>
          )}
          <Button
            type="button"
            className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
            onClick={() => methods.handleSubmit(onSubmit(false))}
            // disabled={formIsError}
          >
            Selanjutnya
            <RiArrowRightLine className="text-white" />
          </Button>
        </div>
      </div>
      {renderModal()}
    </div>
  );
};

export default AddAWPDetailActivity;
