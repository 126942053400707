import { AtomDatePicker, Button, CustomSelect, SearchInput } from "components";
import { TableWrapper } from "components/molecules/ListPage";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import useTorService from "./hooks/useTorService";
import moment from "moment";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { debounce } from "lodash";

const links = [
  {
    label: "Daftar Pengajuan ToR",
  },
];

const Tor = () => {
  const methods = useForm();
  const navigate = useNavigate();
  const [qParams, setQParams] = useState({
    searchKey: "",
    status: "",
    startDate: "",
    endDate: "",
    limit: 10,
    page: 1,
    orderMethod: "desc",
    orderBy: "created_at",
  });

  const { torList, pagination, refetch } = useTorService(qParams);

  const onChangePage = useCallback(
    (val) => setQParams({ ...qParams, page: val }),
    []
  );
  const onChangeRowsPerPage = useCallback(
    (val) => setQParams({ ...qParams, limit: val }),
    []
  );

  const tableColumns = useMemo(() => {
    return [
      {
        id: "no",
        title: "No",
        dataIndex: "no",
        className: "overflow-hidden",
        columnClassName: "w-[50px]",
        render: (value, data, index) => {
          const no =
            parseInt(qParams.page) * parseInt(qParams.limit) -
            parseInt(qParams.limit) +
            index +
            1;
          return (
            <div className="overflow-hidden text-ellipsis w-full max-w-[50px]">
              {no}
            </div>
          );
        },
      },
      {
        id: "created_at",
        title: "Tanggal Pengajuan",
        dataIndex: "created_at",
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        fixed: "center",
        columnClassName: "text-ellipsis overflow-hidden",
        render: (value, row) => {
          const startDate = moment
            .utc(row?.created_at)
            .local()
            .format("DD/MM/YYYY");
          return (
            <div className="w-full overflow-hidden text-ellipsis">
              {startDate}
            </div>
          );
        },
      },
      {
        id: "project_name",
        title: "Proyek",
        dataIndex: "",
        className: "",
        fixed: "center",
        headerClassName: "text-left",
        columnClassName: "text-ellipsis overflow-hidden text-center",
        sortable: true,
        render: (value, row) => (
          <div className="w-full overflow-hidden text-ellipsis text-center">
            {typeof row?.tor?.work_plan_name === "object"
              ? row?.tor?.work_plan_name?.label
              : row?.tor?.work_plan_name}
          </div>
        ),
      },
      {
        id: "output",
        title: "Output",
        dataIndex: "",
        className: "",
        fixed: "center",
        columnClassName: "text-ellipsis overflow-hidden text-center",
        sortable: true,
        render: (value, row) => (
          <div className="w-full overflow-hidden text-ellipsis">
            {row.tor?.output_name}
          </div>
        ),
      },
      {
        id: "work_plan_name",
        title: "Kegiatan Utama",
        dataIndex: "work_plan_name",
        className: "",
        fixed: "center",
        columnClassName: "text-ellipsis overflow-hidden",
        sortable: true,
        render: (value, row) => (
          <div className="w-full overflow-hidden text-ellipsis">
            {row.tor?.awp_activity_name}
          </div>
        ),
      },
      {
        id: "event_detail",
        title: "Detail Kegiatan",
        dataIndex: "",
        className: "",
        fixed: "center",
        columnClassName: "text-ellipsis overflow-hidden",
        sortable: true,
        render: (value, row) => (
          <div className="w-full overflow-hidden text-ellipsis text-center">
            {row.tor?.purpose}
          </div>
        ),
      },
      {
        id: "status",
        title: "Status",
        dataIndex: "status",
        className: "",
        fixed: "center",
        columnClassName: "text-ellipsis overflow-hidden text-center",
        sortable: true,
        render: (value, row) => (
          <div
            className="overflow-hidden text-white font-bold text-[12px]"
            style={{
              textAlign: "-webkit-center",
              color: value === "DRAFTED" ? "#7f7f7f" : "green",
              borderRadius: 100,
            }}
          >
            <p className="p-1 text-sm">
              {value === "DRAFTED" ? "Draft" : "Terkirim"}
            </p>
          </div>
        ),
      },
      {
        id: "actions",
        title: "Aksi",
        dataIndex: "_id",
        className: "",
        sortable: true,
        columnClassName: "w-[250px] text-center text-ellipsis overflow-hidden",
        fixed: "center",
        render: (value, data, index) => {
          return value ? (
            <>
              <div className="flex flex-col justify-center items-center gap-[12px]">
                <div className="flex w-full space-x-3">
                  <MdOutlineRemoveRedEye
                    className="flex-1 text-gray-600 cursor-pointer"
                    size={20}
                    onClick={() => {
                      sessionStorage.setItem("torId", value);
                      // console.log("val", value)
                      navigate("/tor/detail");
                    }}
                  />
                </div>
                <div className="flex justify-center w-full space-x-3">
                  <Button
                    type="button"
                    className={`
                        text-white w-40
                          bg-[#00BD52]
                        `}
                    onClick={() => {
                      sessionStorage.setItem("torId", value);
                      navigate("/tor/revision");
                    }}
                  >
                    {data?.status === "DRAFTED" ? "Lanjutkan Draft" : "Revisi"}
                  </Button>
                </div>
              </div>
            </>
          ) : (
            ""
          );
        },
      },
    ];
  }, []);

  const statusOptions = useMemo(() => {
    return [
      { value: null, label: "Semua" },
      { value: "APPROVED", label: "Terkirim" },
      { value: "DRAFTED", label: "Draft" },
    ];
  });

  useEffect(() => {
    sessionStorage.removeItem("step");
    sessionStorage.removeItem("torId");
    refetch();
  }, [qParams]);

  useEffect(() => {
  const [start, end] = methods.watch("exampleDate") || [null, null];

  setQParams((prev) => ({
    ...prev,
    startDate: moment(start).format("YYYY-MM-DD"),
    endDate: moment(end).format("YYYY-MM-DD"),
  }));
  }, [methods.watch("exampleDate")])

  const onSearch = debounce((event) => {
    setQParams((prev) => ({
      ...prev,
      searchKey: event?.target?.value ?? '',
    }));
  }, 1000);

  const onChangeStatus =(obj) => {
    setQParams((prev) => ({
      ...prev,
      status: obj.value ?? '',
    }));
  };

  return (
    <div>
      <TableWrapper
        links={links}
        title={"Daftar Pengajuan ToR"}
        btnSize={"md"}
        columns={tableColumns}
        dataSource={torList}
        onCreate={() => navigate("/tor/create")}
        customTextAddButton="Ajukan TOR"
        sortMethod={"desc"}
        setSortMethod={() => console.log("first")}
        setSortBy={() => console.log("first")}
        pagination={pagination}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        customFilter={
          <FormProvider {...methods}>
            <div className="flex space-x-2">
              <SearchInput
                controllerName={`search`}
                placeholder="Cari"
                onChange={onSearch}
              />
              <CustomSelect
                optionsData={statusOptions}
                placeholder="Status"
                inputClassName="5px"
                className="items-center self-center flex-1 text-sm text-gray-400 border-gray-200 border-solid rounded-md outline-none focus:outline-none remove-input-txt-border-react-select"
                onChange={onChangeStatus}
              />
              <div className="flex-1">
                <AtomDatePicker
                  className="!mt-0 flex-1 rounded-md border-gray-200 border-solid text-sm outline-none focus:outline-none items-center self-center remove-input-txt-border-react-select"
                  controllerName="exampleDate"
                  label={null}
                  placeholder="Select a date"
                  asText={false}
                  format={"dd/MM/yyyy"}
                  isRangeDatePicker={true}
                  isClearable
                />
              </div>
            </div>
          </FormProvider>
        }
      />
    </div>
  );
};

export default Tor;
